/****************************************/
/*           STANDARD COMPONENTS        */
/****************************************/


/*********** Button Component  **********/



/*********** Products Components  **********/

.ui.card>.image>img, .ui.cards>.card>.image>img {
    height:270px !important;
}

/*********** Admin Table Component  **********/

.admin-table-ct{
    margin:1em;
}

/*********** Points AllTime Box Component  **********/
.pts-alltime-box{
    // border:1px solid #D0D2D3;
    border:1px solid #D0D2D3;
    text-align:center;
    padding: 10px 10px;
    color: #173862;
    font-weight: 700;
    // color: white;
    // background-color: #173862;
}

@media only screen and (max-width: 750px) {
    .pts-alltime-box{
        min-width:10.75em;
        min-height:5vh;
    }
}
@media only screen and (min-width: 751px)  and (max-width: 1079px) {
    .pts-alltime-box{
        min-width:10.75em;
        min-height:5vh;
    }
}
@media only screen and (min-width: 1080px) {
    .pts-alltime-box{
        min-width:10.75em;
        min-height:5vh;
    }
}


/*********** Product Info Component  **********/

@media only screen and (max-width: 750px) {
    .pdct-info{
        margin-top:20px;
        font-size: 10px;
        padding: 0 0px 0 0px;
        color: #5f5f5f;
        font-family: "Raleway", Tahoma,sans-serif;
    }
}

@media only screen and (min-width: 751px)  and (max-width: 1079px) {
    .pdct-info{
        margin-top:25px;
        font-size: 12px;
        padding: 0 0px;
        color: #5f5f5f;
        font-family: "Raleway", Tahoma,sans-serif;

    }
}

@media only screen and (min-width: 1080px) {
    .pdct-info{
        margin-top:150px;
        font-size: 12px;
        color: #5f5f5f;
        font-family: "Raleway", Tahoma,sans-serif;

    }
    
}
