/*******************************
        Form Components
*******************************/

.form-gv-title, .form-gv-desc,  .ui.form .field>label, .ui.search.dropdown>.text, .ui.search.dropdown>.text {
    color: #8d8787;
    padding: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-weight: 400;
    font-size: 12px;
    line-height: 1;
    letter-spacing: 0.00938em;
}


@media only screen and (max-width: 770px) {
    .form-gv-title, .form-gv-desc,  .ui.form .field>label, .ui.search.dropdown>.text, .ui.search.dropdown>.text {
        font-size: 12px;
        line-height: 1.25;
        letter-spacing: 0.10em;
    }
}

@media only screen and (min-width: 771px) {
    .form-gv-title, .form-gv-desc,  .ui.form .field>label, .ui.search.dropdown>.text, .ui.search.dropdown>.text {
        font-size: 13px;
        line-height: 1;
        letter-spacing: 0.00938em;
    }
}

