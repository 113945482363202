/****************************************/
/*           TOPLEVEL COMPONENTS        */
/****************************************/

/*********** HEADER COMPONENT  **********/

.top-header {
    width:100%;
    margin: 0 auto;
}

.sub-header {
    background-color: $color-white;
    margin-top:50px;
    // height:100px;
    padding-left: 150px;
}

.header > .ui.grid{
    margin:0;
}

.header-text {
    color:white;
}

/*********** SUBHEADER COMPONENT  **********/

.breadcrumb-hd-cont{
    background-color: $color-white;
    height:25px;
    margin: 25px auto;
    padding: 2.5px 0px 0px 15px;
}

/*********** ACCOUNT COMPONENT  **********/

@media only screen and (max-width: 750px) {
    
    .splash-body-cont{
        width:100%;
        background-color: $color-white;
        min-height:calc(60vh - 7.5vh);
        margin: 25px auto;
        padding:5% 10%;
        text-align: center;
    }
    
}

@media only screen and (min-width: 751px)  and (max-width: 1079px) {

    .splash-body-cont{
        width:100%;
        background-color: $color-white;
        min-height:calc(60vh - 7.5vh);
        margin: 25px auto;
        padding:5% 20%;
        text-align: center;
    }
    
}

@media only screen and (min-width: 1080px) {

    .splash-body-cont{
        width:100%;
        background-color: $color-white;
        min-height:calc(60vh - 7.5vh);
        margin: 25px auto;
        padding:5% 25%;
        text-align: center;
    }
}

/*********** Products Component  **********/

@media only screen and (max-width: 750px) {
    .products-cont{
        width:100%;
        background-color: $color-white;
        min-height:calc(60vh - 7.5vh);
        margin: 25px auto;
        padding: 0 25px;
    }
    .product-search-cont{
        padding: 0 1.5em;
    }
    
}

@media only screen and (min-width: 751px)  and (max-width: 1199px) {
    .products-cont{
        width:100%;
        background-color: $color-white;
        min-height:calc(60vh - 7.5vh);
        margin: 25px auto;
        padding: 0 25px;
    }
    .products-title{
        padding-left: 35%;
    }
    .product-search-cont{
        padding: 0 3em;
    }
    
}

@media only screen and (min-width: 1200px) {
    .products-cont{
        width:100%;
        background-color: $color-white;
        min-height:calc(60vh - 7.5vh);
        margin: 25px auto;
        padding: 0 25px;
    }
    .products-title{
        padding-left: 60%;
    }
    .product-search-cont{
        padding: 0 7em;
    }
}

.products-disc{
    font-family: $fontForm;
    font-size: 12px;
}

/*********** ACCOUNT HEADER COMPONENT  **********/

@media only screen and (max-width: 750px) {
    .acct-header-cont{
        background-color: $color-white;
        min-height:100px;
        margin: 25px auto;
    }
    
}

@media only screen and (min-width: 751px)  and (max-width: 1079px) {
    .acct-header-cont{
        background-color: $color-white;
        min-height:150px;
        margin: 25px auto;
    }
    
}

@media only screen and (min-width: 1080px) {
    .acct-header-cont{
        background-color: $color-white;
        min-height:150px;
        margin: 25px auto;
    }
}

/*********** Account Body Component  **********/

@media only screen and (max-width: 750px) {
    .acct-body-div{
        width:100%;
        background-color: $color-white;
        min-height:calc(60vh - 7.5vh);
        margin: 25px auto;
    }
    
}

@media only screen and (min-width: 751px)  and (max-width: 1079px) {
    .acct-body-div{
        width:100%;
        background-color: $color-white;
        min-height:calc(60vh - 7.5vh);
        margin: 25px auto;
    }
    
}

@media only screen and (min-width: 1080px) {
    .acct-body-div{
        width:100%;
        background-color: $color-white;
        min-height:calc(60vh - 7.5vh);
        margin: 25px auto;
    }
}

.acct-body-dimmer{
    position: relative;
    height:calc(60vh - 7.5vh) !important;
}

.acct-body-dimmer-body{
    top: 50%;
    left: 0%;
}

.acct-body-div{
    padding: 0 30px;
}

.acct-body-btn-group p{
    margin: 20px 0;
}

.acct-body-btn-group .buttons{
    margin-bottom: 20px;
}

.acct-body-table-group{
    margin-top:25px;
}

.acct-body-txns-btn{
    text-align: right;
}

/*********** Account Header Component  **********/

.acct-header-div{
    padding:0 10px;
}

.acct-header-segment{
    text-align:center;
}

.acct-header-segment img{
    margin-bottom: 15px;
}

.acct-hd-sg-total{
    padding:0% 0;
    .label {
        padding-top:0px;
        color:grey !important;
        font-weight: 700 !important;
        font-family: $fontForm !important;
    }
    .value {
        // color:#003057 !important;
        color: #0072CE !important;
        font-weight: 700 !important;
        font-family: $fontForm !important;
    }
}

.acct-hd-sg-pts{
    .value {
        color: #0072CE !important;
        font-size: 12px !important;
        font-weight: 700 !important;
        font-family: $fontForm !important;
    }
    .label {
        padding-top:5px;
        color:grey !important;
        font-size: 10px !important;
        font-weight: 700 !important;
        font-family: $fontForm !important;
    }

}

.acct-hd-sg-pts {
    float:left; 
    width:100%;
    text-align: center; 
}

.totalpoints-cont {
    margin: 15px 0 20px 0;
    // padding-top: 15px;
    font-size: 20px;

    font-weight: 700;
    font-family: $fontFamily;
    .totalpoints-label{
        float: left;
        margin-right: 10px;
        color: $color-blue;
    }
    .totalpoints-data {
        margin-left: 20px;
        color: $color-blue;
    }
    
}


@media only screen and (max-width: 750px) {
    .acct-header-avatar{
        padding: 15px 0 0 30px;
    }
    .totalpoints-cont {
        font-size: 14px;
    }
    .acct-header-disclaimer{
        font-size: 10px;
        padding: 0 15px 0 30px;
    }
}

@media only screen and (min-width: 751px)  and (max-width: 1079px) {
    .acct-header-avatar{
        padding: 15px 0 0 75px;
    }
    .acct-header-disclaimer{
        font-size: 10px;
        padding: 0 75px;
    }
    
}

@media only screen and (min-width: 1080px) {
    .acct-header-avatar{
        padding: 50px 0 0 75px;
    }
    .totalpoints-cont {
        padding-top: 25px;
    }
    .acct-header-disclaimer{
        font-size: 12px;
    }
    
}

/*********** Footer Component  **********/

.footer-ct{
    width:100%;
    background-color: $color-darkblue;
}

@media only screen and (max-width: 750px) {
    .footer-ct{
        height:10vh;
    }

    .footer-link{
        color:white;
        font-family: 'Oswald', Verdana, Tahoma, sans-serif;
        font-size: 8px;
    }
    .footer-img{
        width:72px;
        height:26px;
    }
  }

  @media only screen and (min-width: 751px) {
    .footer-ct{
        height:6.5vh;
    }
    .footer-link{
        color:white;
        font-family: 'Oswald', Verdana, Tahoma, sans-serif;
        font-size: 13px;
    }
  }

