/*********** HOME VIEW  **********/

.home-ct{
    min-height:92.5vh;
}

/*********** REDEEM VIEW  **********/


/*********** USER VIEW **********/



/*********** ADMIN VIEW **********/


.admin-ct {
    background: white;
    min-height:92.5vh;
    .ui.menu {
        margin:0;
    }
}