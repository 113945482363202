/*********** DEPENDENCIES + VARIABLES  **********/
//reset file/code
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif; 
} 

// FONTS
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// $fontFamily: 'Oswald',Verdana, Tahoma, sans-serif, Verdana, Tahoma, sans-serif;
$fontFamily: 'Raleway', Tahoma, sans-serif;
$fontForm: 'Raleway', Tahoma, sans-serif;


//COLORS
$orange: #d14124;
$border-solid: #D0D2D3;
$color-white:#ffffff;
$color-primary: #9b59b6;
$color-danger: #e74c3c;
$color-blue: #183d96;
$color-darkblue: #173862;
$color-lightblue:#85b7d9;

//COMPONENTS
@import "./scss/views.scss";
@import "./scss/topcomponents.scss";
@import "./scss/components.scss";
@import "./scss/messages.scss";
@import "./scss/form.scss";

/*********** OVERRIDES  **********/
.ui.orange.button, .ui.orange.buttons .button {
  background-color: #d14124;
}

.ui.blue.button, .ui.blue.buttons .button {
  background-color: #003057;
}

.ui.form, .ui.selection.dropdown, .button, input, textarea {
  border-radius: 0px !important;
}


/*********** ROOT-LEVEL APP  **********/
.App {
  width:100%;
  height:100%;
  background-image: url('./assets/map.gif');
  background-size: cover;
};

.App-Header{
  height:45px;
  background-color: #003057;
  background-image: url("./assets/blue-dots.png");
  background-repeat: repeat 0px 0px;
  font-size: calc(10px + 2vmin);
  color: white;
}

.MuiOutlinedInput-root{
  border-radius:0px !important;
}

// .MuiOutlinedInput-root :focus{
//   border-color:$color-lightblue !important;
//   background:#fff;
//   color:rgba(0,0,0,0.8);
//   box-shadow: none;
// }

.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input:focus{
  border-color:$color-lightblue !important;
  background:#fff;
  color:rgba(0,0,0,0.8);
  box-shadow: none;
}


.MuiButton-root .MuiButton-contained .MuiButton-containedPrimary .MuiButton-sizeMedium .MuiButton-containedSizeMedium .MuiButtonBase-root {
  border-radius: 0 !important;
}

.MuiCardMedia-root {
  object-fit: contain !important;
}

.title {
  font-family: $fontFamily;
  font-weight: 800;
  font-size: calc(10px + 1vmin);
  color: black;
}

.element-space-height {
  margin-top:25px;
  margin-bottom: 25px;
}

.element-space-width {
  margin-left:25px;
  margin-right: 25px;
}
